import React, {useEffect, useState} from "react";
import {doc, getDoc, serverTimestamp, setDoc} from "firebase/firestore";
import {db} from "../../firebaseConfig";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "i18next";

const Server = () => {
  const {t} = useTranslation();
  const { id, language } = useParams();

  const [user, setUser] = useState({});

  window.location.href = `labelica://GameEngine/${id}`;

  useEffect(() => {
    i18n.changeLanguage(language);
    const id = localStorage.getItem('wallet_id');

    const validateId = async () => {
      if (id) {
        await fetchUserData(id);
      } else {
        await fetchIpAddressAndAddUser();
      }
    }

    validateId();
  }, []);

  const fetchUserData = async (id: string) => {
    try {
      const docRef = doc(db, 'users', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUser({...docSnap.data(), id: docSnap.id})
      } else {
        console.log('No such document!');
      }
    } catch (e) {
      console.error('Error fetching user document:', e);
    }
  };

  const fetchIpAddressAndAddUser = async () => {
    try {
      const response = await axios.get(`https://ipapi.co/json/`);
      const ipAddress = response.data.ip;
      const userDoc = {
        ip_address: ipAddress,
        user_agent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        screen_resolution: {
          width: window.screen.width,
          height: window.screen.height,
        },
        timestamp: serverTimestamp(),
      };
      await addUserData(userDoc);
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  const addUserData = async (userDoc: any) => {
    try {
      const userId = uuidv4();
      const docRef = doc(db, 'users', userId);

      await setDoc(docRef, userDoc);

      localStorage.setItem('wallet_id', docRef.id);
      setUser({...userDoc, id: docRef.id});
    } catch (e) {
      console.error('Error adding user document:', e);
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '2rem' }}>
      <div style={{display: 'flex', justifyContent: 'center', fontSize: 20}}>
        <a
          href={'https://apps.apple.com/app/6615091866'}
        >
          {t('server.text')}
        </a>
      </div>
    </div>
  );
}

export default Server;
