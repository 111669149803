import React, {useState, useEffect} from "react";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import i18n from "i18next";

const Banner = ({user, onClick, language}: any) => {
  const { amount = 0 } = user;

  const options = [
    {
      value: "es",
      option: "Español",
      label: (
        <img src={require('../../assets/images/es.png')} width={30} alt="Spanish" />
      )
    },
    {
      value: "en",
      option: "English",
      label: (
        <img src={require('../../assets/images/en.png')} width={30} alt="English" />
      )
    },
  ];

  const [langLabel, setLangLabel] = useState(options[0].label);

  const onChange = (n: number) => {
    const selectedLang = options[n].value;
    setLangLabel(options[n].label);

    i18n.changeLanguage(selectedLang);
  }

  useEffect(() => {
    if (language) {
      const index = options.findIndex(option => option.value === language);
      setLangLabel(options[index].label);
    }
  }, [])

  return (
    <div className="Banner-wrapper">
      <div className="Flex">
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            className=" bg-transparent btn-outline-secondary border-0 "
            id="lng-dropdown"
          >
            {langLabel}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onChange(0)}>
              {options[0].option}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onChange(1)}>
              {options[1].option}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{display: "flex", justifyContent: "center", minWidth: "100%"}}>
        <span className="Banner-text">La Belica</span>
      </div>
      {/*<div className="Flex">
        <button disabled={amount === 0} className="Flex" onClick={onClick} style={{backgroundColor: 'transparent', height: 60, borderRadius: 20}}>
          <img className="Avatar" src={require('../../assets/images/token.png')} alt="avatar"/>
          <p className="Banner-text">
            {`$ ${amount.toLocaleString()} `}
            <span style={{fontFamily: 'Snell Roundhand, Dancing Script, cursive'}}>LB</span>
          </p>
        </button>
      </div>*/}
    </div>
  );
}

export default Banner;
